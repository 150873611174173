import './public-path'

import Vue from 'vue'
import I18n from 'vue-i18n'

import { install } from '@/install'
import { createApp } from '@/app'

import './styles/index.sass'
import '@utils/events'

import appComponents from './js/components'

Vue.config.productionTip = false

Vue.use(install)
Vue.use(appComponents)

const { app } = createApp({
  Vue,
  I18n,
})

app.$mount('#app')
